import React, {useRef, useState} from 'react'
import {Link} from 'gatsby'
import Image from 'gatsby-image'
import ReactPlayer from 'react-player'
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Footer from '../Footer'
import HomeMostUpcomingEvent from '../views/HomeMostUpcomingEvent'
import FeaturedLocalBusiness from '../views/FeaturedLocalBusiness'

import PauseButtonIcon from '../../images/icons/pause.svg'
import PlayButtonIcon from '../../images/icons/play.svg'

import AudioButtonIcon from '../../images/icons/audio.svg'
import MuteButtonIcon from '../../images/icons/mute.svg'

// import WelcomeToGreeley from '../../images/welcome-to-downtown-greeley.svg'
import InfoIcon from '../../images/icons/info.svg'

import styles from './home.module.css'
import './slider.css'

const Home = ({slideshow, videoID}) => {
  // console.log({videoID})
  const [videoData, setVideoData] = useState({
    muted: true,
    volume: 0,
    playing: false,
    pause: true,
    ready: false
  })
  const handlePause = () => {
    setVideoData({...videoData, playing: false})
  }

  const handlePlay = () => {
    setVideoData({...videoData, playing: true})
  }
  const handleAudio = () => {
    setVideoData({...videoData, muted: false, volume: 0.5})
  }
  const handleMute = () => {
    setVideoData({...videoData, muted: true, volume: 0})
  }

  const messagesEndRef = useRef(null)
  const [scrollDownButton, setScrollDownButton] = useState(true)

  const scrollToContent = () => {
    messagesEndRef.current.scrollIntoView({behavior: 'smooth'})
    setScrollDownButton(false)
  }
  return (
    <div className='home'>

      <article className={styles.homeWrapper}>
        <div className={videoID ? styles.imgWrapper : styles.imgWrapper2}>
          {scrollDownButton &&
          <button onClick={scrollToContent} className={styles.scrollDown} aria-label='Scroll to content'>
            <svg width='25' height='14' viewBox='0 0 25 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M.695 1.696A1.385 1.385 0 011.554.41a1.389 1.389 0 011.514.301l9.627 9.628L22.323.712a1.389 1.389 0 111.965 1.966l-10.61 10.61a1.389 1.389 0 01-1.965 0L1.103 2.678a1.383 1.383 0 01-.408-.982z' fill='#fff' /></svg></button>}

          {videoID && (<div className={styles.videoWrapper}>
            <div className={styles.videoHomeBtnWrapper}>
              <div>
                {videoData.muted === true ? (
                  <button className={styles.audioBtn} onClick={handleAudio} aria-label='Video Audio'><AudioButtonIcon /></button>
                ) : (
                  <button className={styles.audioBtn} onClick={handleMute} aria-label='Video Audio'><MuteButtonIcon /></button>
                )}

                {videoData.ready && videoData.playing ? (
                  <button className={styles.pauseBtn} onClick={handlePause} aria-label='Pause Video'><PauseButtonIcon /></button>
                ) : (
                  <button className={styles.playBtn} onClick={handlePlay} aria-label='Play Video'><PlayButtonIcon /></button>
                ) }
              </div>
            </div>
            <ReactPlayer
              url={`https://vimeo.com/${videoID}?background=1`}
              className={styles.embedContainer}
              muted={videoData.muted}
              volume={videoData.volume}
              playsinline
              light={false}
              loop
              width='100%'
              height='100%'

              playing={videoData.playing}
              playIcon='true'
              controls={false}
              onReady={() => setVideoData({ready: true, playing: true, muted: true, volume: 0})}
              onStart={() => setVideoData({playing: true, ready: true, muted: true, volume: 0})}
            />
          </div>)}
          {(slideshow.slides && !videoID) &&
          <Carousel className={styles.carouselWrapper} interval={10000} showThumbs={false} showStatus={false} showIndicators={false} autoPlay stopOnHover={false} useKeyboardArrows infiniteLoop emulateTouch>
            {slideshow.slides.map(slide => (
              <div key={slide._key} >
                {slide.title && (
                  <div className='sideText'><InfoIcon />
                    {slide.link ? (<Link to={slide.link}>{slide.title}</Link>) : (<>{slide.title}</>)}
                  </div>
                )}
                <Image fluid={slide.slideImage.asset.fluid} alt={slide.title} />
              </div>
            ))}
          </Carousel>
          }

          <div className={styles.homeContent} aria-live='polite' ref={messagesEndRef}>
            <HomeMostUpcomingEvent />
            <FeaturedLocalBusiness />
          </div>
          <Footer />
        </div>

      </article>

    </div>
  )
}
export default Home
