import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import Image from 'gatsby-image'

import LinkIcon from '../../lib/LinkIcon'

import styles from './featuredlocalbusiness.module.css'

const FeaturedLocalBusiness = () => {
  const data = useStaticQuery(graphql`
    {
      sanityHome {
        featuredLocalBusiness {
          title
          slug {
            current
          }
          shortDescription
          links {
            _key
            link
            linkType
          }
          location {
            streetAddress
            zipcode
            city
            state
          }
          teaserImage {
            asset {
              fluid(maxWidth: 130, maxHeight: 130) {
                ...GatsbySanityImageFluid
              }
            }
          }
          businessContact {
            contactEmail
            contactPhone
          }
        }
      }
  }
  `)
  const biz = data.sanityHome.featuredLocalBusiness
  // console.log({biz})
  // data.sanityHome.featuredLocalBusiness
  return (
    <>
      {biz && (
        <div className={styles.featuredLocalBusiness}>
          <h3>Featured Local Business</h3>

          <div className={styles.bizWrapper}>

            <div className={styles.left}>
              <h4><Link to={`/business-directory/${biz.slug.current}`}>{biz.title}</Link></h4>
              <p className={styles.bizAddress}>{biz.location.streetAddress} <br />{biz.location.city}, {biz.location.state} {biz.location.zipcode}</p>

              {/* icons */}
              <div className={styles.icons}>

                {biz.links.map(link => (
                  <a href={link.link} key={link._key} aria-label={`${biz.title} ${link.linkType} link`} target='_blank' rel='noopener noreferrer' title={`${biz.title} ${link.linkType} link`}><LinkIcon type={link.linkType} /></a>
                ))}

              </div>

              <p className={styles.bizDetails}>{biz.shortDescription}</p>

            </div>

            <div className={styles.right}>
              {biz.teaserImage && biz.teaserImage.asset && biz.teaserImage.asset.fluid && (
                <div className={styles.imgWrapper}>
                  <Link to={`/business-directory/${biz.slug.current}`}><Image fluid={biz.teaserImage.asset.fluid} alt='hero' /></Link>
                </div>
              )}
              <p className={styles.cta}><Link to={`/business-directory/${biz.slug.current}`}>View Business</Link></p>
            </div>

          </div>

          <div className={styles.ctaMore}>
            <p><Link to='/business-directory/'>More Businesses</Link></p>
          </div>

        </div>
      )}
    </>
  )
}

export default FeaturedLocalBusiness
