import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

// import Image from 'gatsby-image'
import moment from 'moment'
// import { DateRange } from 'react-date-range';
import { DateRangePicker } from 'react-date-range'; //defaultStaticRanges
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  startOfYear,
  endOfYear,
} from 'date-fns';
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file


import styles from './homemostupcomingevent.module.css'

import './OurCalendar.css' // theme css file


export default function HomeMostUpcomingEvent() {
  const [events, setEvents] = useState([])
  const [allEvents, setAllEvents] = useState([])
  const [startDate, setStartDate] = useState(startOfDay(new Date()))
  const [endDate, setEndDate] = useState(endOfYear(new Date()))

  const data = useStaticQuery(graphql`
   {
    allSanitySpecialEvent(sort: {fields: sortOrder, order: ASC}) {
      edges {
        node {
          slug {
            current
          }
          _id
          title
          startDate {
              local
            }
          endDate {
              local
            }
          teaserImage {
            asset {
              url
              fluid(maxWidth: 250) {
                ...GatsbySanityImageFluid
              }
            }
          }
          teaserText
        }
      }
    }
  }
  `)

  const reshapedData = data?.allSanitySpecialEvent?.edges?.map(item => {
    return {
      slug: item?.node?.slug?.current,
      _id: item?.node?._id,
      title: item?.node?.title,
      startDate: item?.node?.startDate?.local,
      endDate: item?.node?.endDate?.local,
      teaserImage: item?.node?.teaserImage,
      teaserText: item?.node?.teaserText
    };
  }) || [];

  // Sort the array based on startDate in ascending order
  const eventData = reshapedData.sort((a, b) => new Date(a.startDate) - new Date(b.startDate)) || [];

  // The most upcoming event will be the first element in the sorted array
  const mostUpcomingEvent = eventData[0] || {};

  const EventDateFormat = ({ start, end }) => {
    const shortDayTime = `${moment(start).format('MMM Do YYYY, h:mma')} to ${moment(end).format('h:mma')}`
    const longDayTime = `${moment(start).format('MMM Do YYYY, h:mma')} to ${moment(end).format('MMM Do YYYY, h:mma')}`
    return moment(start).isSame((end), 'day') ? shortDayTime : longDayTime
  }

  return (
    <div className={styles.upcomingEvents}>
      <h3>Upcoming Featured Event</h3>
      <div className={styles.eventsWrapper}>

        {mostUpcomingEvent && mostUpcomingEvent?.slug && (
          <div className={styles.mostUpcomingEventWrapper}>

            <div className={styles.eventTeaserImage}>
              <Link to={`/special-events/${mostUpcomingEvent.slug}`}>
                <Image fluid={mostUpcomingEvent.teaserImage.asset.fluid} alt={mostUpcomingEvent.title} />
              </Link>
            </div>

            <h4>Learn more about <Link to={`/special-events/${mostUpcomingEvent.slug}`}>{mostUpcomingEvent?.title}</Link></h4>
          </div>)}

      </div>

      <div className={styles.ctaMore}>
      <p><Link to='/special-events/'>More Featured Events</Link></p>
    </div>

    </div>
  )
}
